exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-antraege-infos-js": () => import("./../../../src/pages/antraege-infos.js" /* webpackChunkName: "component---src-pages-antraege-infos-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-ihre-unterstuetzung-js": () => import("./../../../src/pages/ihre-unterstuetzung.js" /* webpackChunkName: "component---src-pages-ihre-unterstuetzung-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-vorstand-js": () => import("./../../../src/pages/vorstand.js" /* webpackChunkName: "component---src-pages-vorstand-js" */),
  "component---src-pages-was-wir-tun-js": () => import("./../../../src/pages/was-wir-tun.js" /* webpackChunkName: "component---src-pages-was-wir-tun-js" */)
}

